import {
  borderColor,
  borderColorDisabled,
  borderRadiusButton,
  buttonTypography,
  fontColorInactive,
  fontColorPrimary,
  fontColorQuaternaryButton,
  fontColorSecondary,
  primaryColor,
  primaryColorDisabled,
  primaryColorFocused,
  primaryColorHover,
  quaternaryColorDisabled,
  quaternaryColorFocused,
  quaternaryColorHover,
  secondaryColor,
  secondaryColorDisabled,
  secondaryColorFocused,
  secondaryColorHover,
  shadowButton,
  tertiaryColorErrorDisabled,
  tertiaryColorErrorFocus,
  tertiaryColorErrorHover,
  tertiaryColorFocused,
  warningColor,
  fifthColor,
  fifthColorDisabled,
  secondaryColorSelected,
  quaternaryColor,
  fontFamilyName
} from "../../../styles/mui/theme";

const commonButtonProperties = {
  minHeight: "50px",
  height: "fit-content",
  padding: "11px 30px",
  borderRadius: borderRadiusButton,
  textAlign: "center",
  textTransform: "uppercase",
  fontFamily: fontFamilyName,
};

export const muiStylesButtons = {
  loginButton: {
    ...buttonTypography,
    ...commonButtonProperties,
    "width": "315px",
    "backgroundColor": primaryColor,
    "boxShadow": shadowButton,
    "color": fontColorPrimary,
    ":hover": {
      backgroundColor: primaryColorHover,
    },
    ":disabled": {
      backgroundColor: primaryColorDisabled,
      boxShadow: "none",
      color: fontColorInactive,
    },
    ":focus": {
      backgroundColor: primaryColorFocused,
    },
  },
  primaryButton: {
    ...buttonTypography,
    ...commonButtonProperties,
    "backgroundColor": primaryColor,
    "boxShadow": shadowButton,
    "color": fontColorPrimary,
    ":hover": {
      backgroundColor: primaryColorHover,
    },
    ":disabled": {
      backgroundColor: primaryColorDisabled,
      boxShadow: "none",
      color: fontColorInactive,
    },
    ":focus": {
      backgroundColor: primaryColorFocused,
    },
  },
  secondaryButton: {
    ...buttonTypography,
    ...commonButtonProperties,
    "backgroundColor": secondaryColor,
    "border": "2px solid",
    "borderColor": borderColor,
    "boxShadow": shadowButton,
    "color": fontColorSecondary,
    ":hover": {
      backgroundColor: secondaryColorHover,
      border: "2px solid",
      borderColor: secondaryColorFocused,
      color: secondaryColorFocused,
    },
    ":disabled": {
      backgroundColor: secondaryColorDisabled,
      borderColor: borderColorDisabled,
      boxShadow: "none",
      color: fontColorInactive,
    },
    ":focus": {
      backgroundColor: secondaryColorHover,
    },
  },
  secondaryButtonConsent: {
    padding: "16px 21px",
  },
  tertiaryButton: {
    ...buttonTypography,
    ...commonButtonProperties,
    "backgroundColor": primaryColor,
    "boxShadow": shadowButton,
    "color": fontColorPrimary,
    ":hover": {
      backgroundColor: primaryColorHover,
    },
    ":disabled": {
      backgroundColor: secondaryColorSelected,
    },
    ":focus": {
      backgroundColor: tertiaryColorFocused,
    },
    "delete": {
      backgroundColor: warningColor,
    },
    "error": {
      "width": "135px",
      "backgroundColor": warningColor,
      ":hover": {
        backgroundColor: tertiaryColorErrorHover,
      },
      ":disabled": {
        backgroundColor: tertiaryColorErrorDisabled,
      },
      ":focus": {
        backgroundColor: tertiaryColorErrorFocus,
      },
    },
    "removePadding": {
      marginBottom: "0",
      height: "fit-content",
      padding: "0",
    },
  },
  quaternaryButton: {
    ...buttonTypography,
    ...commonButtonProperties,
    "backgroundColor": quaternaryColor,
    "color": fontColorQuaternaryButton,
    "border": "2px solid",
    "borderColor": quaternaryColor,
    ":hover": {
      color: quaternaryColorHover,
      backgroundColor: quaternaryColor,
      border: "2px solid",
      borderColor: quaternaryColorHover,
    },
    ":disabled": {
      backgroundColor: quaternaryColor,
      color: quaternaryColorDisabled,
    },
    ":focus": {
      color: quaternaryColorFocused,
      backgroundColor: quaternaryColor,
      border: "2px solid",
      borderColor: quaternaryColorFocused,
    },
    "removePadding": {
      marginBottom: "0",
      height: "fit-content",
      padding: "0",
    },
  },
  fifthButton: {
    ...buttonTypography,
    ...commonButtonProperties,
    "textTransform": "none",
    "backgroundColor": fifthColor,
    "color": fontColorSecondary,
    "width": "100px",

    ":hover": {
      color: secondaryColorFocused,
      backgroundColor: secondaryColorSelected,
    },
    ":disabled": {
      color: fifthColorDisabled,
      backgroundColor: fifthColor,
    },
    ":focus": {
      color: secondaryColorFocused,
      backgroundColor: secondaryColorSelected,
    },
    "removePadding": {
      marginBottom: "0",
      height: "fit-content",
      padding: "0",
    },
  },
  crudButton: {
    ...buttonTypography,
    height: "50px",
    color: fontColorPrimary,
    textAlign: "center",
    textTransform: "uppercase",
    delete: {
      button: {
        "color": warningColor,
        "backgroundColor": quaternaryColor,
        "border": "2px solid",
        "borderColor": quaternaryColor,
        ":hover": {
          color: warningColor,
          backgroundColor: quaternaryColor,
          border: "2px solid",
          borderColor: warningColor,
        },
        ":focus": {
          color: warningColor,
          backgroundColor: quaternaryColor,
          border: "2px solid",
          borderColor: warningColor,
        },
      },
    },
  },
  loader: {
    color: fontColorPrimary,
  },
};
